import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { currencyconverter } from "../../../../common/currencyconverter";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import ExportMenu from "../../../School/ExportMenu";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import HttpComponent from "../../../School/MakeRequest";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../../../utils/dateFormatter";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Zed Payit Report</Typography>,
]

export default function CashierDrillDownPayit() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, total: 0, noOfOrders: 0 });
    const [orderNumber, setOrderNumber] = useState('');
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("This Month")
    const [handleDaysChange1, setHandleDaysChange1] = useState("This Month")
    const navigate = useNavigate()

    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, userId, Name] = newqueryParamValue.split("?")

    function fetchPayitOrderBycashierbyId(){
        setPageState((prev) => ({ ...prev, isLoading: true }))
        try{
            HttpComponent({
                method:'GET',
                url:`/api/v1/groupedorderbycashierId?userId=${userId}&page=${pageState.page}&limit=${dataGridPageSize}&startDate=${startDate}&endDate=${endDate}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data)=>{
                if(data?.status === 200){
                    console.log(data?.response , 'bitches')
                    setPageState((prev) => ({ ...prev, isLoading: false, data: data?.response?.data , count:data?.response?.count , total:data?.response?.cashierTotal}))
                }
            })
        }catch(error){
            console.log(error)
        }finally{
            setPageState((prev) => ({ ...prev, isLoading: false }))
        }
    }
    useEffect(() =>{
        fetchPayitOrderBycashierbyId()
    },[pageState.page, dataGridPageSize , userId , startDate , endDate])


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                break;
        }
    }, [handleDaysChange]);

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        orderNumber: config?.orderNumber,
        Amount: currencyconverter(config?.transamount),
        paymentMethod: config?.paymentMethod,
        CreatedAt:DateFormatter(config?.createdAt),
    }))

    const payitOrders = [
        { field: 'orderNumber', headerName: 'Order No', flex: 1},
        { field: 'paymentMethod', headerName: 'Payment Method', flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
        { field: 'CreatedAt', headerName: 'Transaction Time', flex: 1 },

    ]

    const filedata = pageState?.data?.map((data) => {
        return {
            "orderNumber": data?.orderNumber,
            "Payment Method": data?.paymentMethod,
            "Amount": currencyconverter(data?.totalAmount),
        }
    })
    const fileHeaders = [["Order No", "Payment Method", "Amount"]]
    const csvColumns = [
        { label: "Order No", key: "Order No" },
        { label: "Payment Method", key: "Payment Method" },
        { label: "Amount", key: "Amount" },
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.orderNumber, data?.paymentMethod, currencyconverter(data?.Amount)]);
    return (
        <Grid container spacing={2} direction={'column'}>
             <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={() =>navigate(-1)} style={{ color: "#032541" }} />
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>{`Zed Payit Report - ${Name}`}</Typography>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
                <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                    <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> No of Orders <span style={{ fontWeight: 700 }}>{pageState.count}</span></Typography>
                    </Paper>
                    <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> Total Amount <span style={{ fontWeight: 700 }}>{currencyconverter(pageState.total)}</span></Typography>
                    </Paper>
                </Box>
            </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} width={'100%'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Box component={'div'} width={'30%'}>
                        <SearchFilter placeholder={"Search"} setInputValue={setOrderNumber} />
                    </Box>
                    <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                        <Grid item display={'flex'}>
                            <FormControl sx={{ width: '190px', height: "50px" }}>
                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Date"
                                    value={handleDaysChange}
                                    sx={{ height: '100%' }}
                                    onChange={(e) => setHandleDaysChange(e.target.value)}
                                >
                                    <MenuItem value={'Today'}>Today</MenuItem>
                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'All Transactions'} title={'Student wallete report'} />
                        </Grid>
                    </Box>
                </Box> 
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={payitOrders} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}