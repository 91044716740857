import React, { useState, useEffect } from "react";
import { Box, Breadcrumbs, Button, Grid, List, ListItem, MenuItem, Tab, Typography, styled, TextField, IconButton } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from './CustomSearchInput';
import Switch from '@mui/material/Switch';
import Modal from "@mui/material/Modal";
import ZedaccImg from '../../images/zedacc.svg'
import EnableSmallIcon from "../../components/School/Images/path-25436-2.svg";
import EnableBigIcon from "../../components/School/Images/path-25436.svg";
import userIcon from "../../components/School/Images/userIcon.svg";
import warehouseIcon from "../../components/School/Images/warehouse-icn.svg";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import HttpComponent from "./MakeRequest";
import PayitImg from "../../images/payit.svg"
import axios from "axios";
import jwtDecode from "jwt-decode";
import { switchWarehouseState } from "../../features/sideBarSlice";
import StockFunctions from "../products/stockComponents/stockEndpoints";
import { useDispatch, useSelector } from "react-redux";
import SchoolGradeAutogenerateConfigSetUp from "./studentpromotion/SchoolGradesAutogconfig";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ArrowBackIos } from "@mui/icons-material";

// payables
import PayablesIcon from "../../components/School/Images/payables_icn.png"
import PayablesIcon1 from "../../components/School/Images/payablesicon1.svg"
import PayablesIcon2 from "../../components/School/Images/payablesicon2.svg"
import PayablesIcon3 from "../../components/School/Images/payablesicon3.svg"
import SelfOrderPng from "../../images/OrderSelf.svg"
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import StudentOrderConfig from "./studentpromotion/StudentOrderConfig";
import StudentOrderConfigSave from "./studentpromotion/studentOrderConfigsave";
import { set } from "lodash";
import { position } from "stylis";




const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Configurations
    </Typography>
];

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const style = { borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 4, };
const zedAccountingmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "700px", height: "490px", p: 2,
}
const zedDashboardmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "700px", height: "290px", p: 2,
}



const baseUrl = process.env.REACT_APP_BASE_URL;

//styled switch
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const SchoolConfigurationEnable = () => {
    const [OpenConfigurationModal, setOpenConfigurationModal] = useState(false);
    const [openZedAccountingModal, setOpenZedAccountingModal] = useState(false);
    const [openXeroAccountingModal, setXeroAccountingModal] = useState(false);
    const [openZohoAccountingModal, setZohoAccountingModal] = useState(false);
    const [openQuickBooks, setQuickBooksModal] = useState(false);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [openZedPayit, setOpenZedPayit] = useState(false)
    const [accountStatus, setAccountStatus] = useState()
    const [ipsasStatus, setIpsasStatus] = useState()
    const [xeroStatus, setXeroStatus] = useState(false)
    const [zohoStatus,setZohoStatus] = useState()
    const [quickBooksStatus, setQuickBooksStatus] = useState(false)
    const [webHookStatus, setWebHookStatus] = useState(false)
    const [payitStatus, setPayitStatus] = useState()
    const [payablesStatus, setPayablesStatus] = useState(false)
    const [openPayablesModal, setOpenPayablesModal] = useState(false)
    const {schoolTypeName} = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [openEnableSelfOrdering , setOpenEnableSelfOrdering] = useState(false)
    const [openIpsasModal, setOpenIpsasModal] = useState(false)
    const businessNumber = localStorage.getItem('businessId')
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [webHook, setWebHook] =  useState('')
    const [quickBookId, setQuickBookId] = useState('')
    const [quickBookSecret, setQuickBookSecret] = useState('')
    const [quickBookHook, setQuickBookHook] = useState('')
    const [showQuickBookId, setShowQuickBookId] = useState(false);
    const [showQuickBookSecret, setShowQuickBookSecret] = useState(false);
    const [showQuickBookWebHook, setShowQuickBookWebHook] = useState(false);
    const [showClientId, setShowClientId] = useState(false);
    const [showClientSecret, setShowClientSecret] = useState(false);
    const [showWebHook, setShowWebHook] = useState(false);
    const [originalClientSecret, setOriginalClientSecret] = useState('');
    const [originalClientId, setOriginalClientId] = useState('');
    const [originalWebHook, setOriginalWebHook] = useState('');
    const [originalQuickBookSecret, setOriginalQuickBookSecret] = useState('');
    const [originalQuickBookId, setOriginalQuickBookId] = useState('');
    const [originalQuickBookWebHook, setOriginalQuickBookWebHook] = useState('');
    const [zohoClientId, setZohoClientId] = useState('')
    const [zohoClientSecret, setZohoSecret] = useState('')
    const [zohoWebhookKey, setZohoWebhook] = useState('')
    const [originalZohoClientSecret, setOriginalZohoClientSecret] = useState('');
    const [originalZohoClientId, setOriginalZohoClientId] = useState('');
    const [originalZohoWebHook, setOriginalZohoWebHook] = useState('');
    const [showZohoClientId, setZohoShowClientId] = useState(false);
    const [showZohoClientSecret, setZohoShowClientSecret] = useState(false);
    const [showZohoWebHook, setZohoShowWebHook] = useState(false);

    const handleOpenseforderModal = ()=>{
        setOpenEnableSelfOrdering(true)
    }

    const handleClosenseforderModal = ()=>{
        setOpenEnableSelfOrdering(false)
    }

    const [zedPayitStatus, setZedPayitStatus] = useState()

    const stockfunctions = new StockFunctions();
    const dispatch = useDispatch();
    const [zedAccountingStatus, setZedAccountingStatus] = useState(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        return savedStatus ? JSON.parse(savedStatus) : false;
    })

    const maskClientId = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setClientId(maskedValue);
        setOriginalClientId(value); 
        return maskedValue;
    }; 
    
    const maskClientSecret = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setClientSecret(maskedValue);
        setOriginalClientSecret(value); 
        return maskedValue;
    }; 

    const maskWebhook = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setWebHook(maskedValue);
        setOriginalWebHook(value); 
        return maskedValue;
    }; 

    const maskQuickBookId = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setQuickBookId(maskedValue);
        setOriginalQuickBookId(value);  
        return maskedValue;
    }; 

    const maskQuickBookSecret = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setQuickBookSecret(maskedValue);
        setOriginalQuickBookSecret(value);  
        return maskedValue;
    }; 

    const maskQuickBookWebhook = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setQuickBookHook(maskedValue);
        setOriginalQuickBookWebHook(value);  
        return maskedValue;
    }; 
        
    const maskZohoClientId = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setZohoClientId(maskedValue);
        setOriginalZohoClientId(value); 
        return maskedValue;
    }; 
    
    const maskZohoClientSecret = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setZohoSecret(maskedValue);
        setOriginalZohoClientSecret(value); 
        return maskedValue;
    }; 

    const maskZohoWebhook = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setZohoWebhook(maskedValue);
        setOriginalZohoWebHook(value); 
        return maskedValue;
    };

    const toggleClientIdVisibility = () => {
        setShowClientId(!showClientId);
        if (!showClientId) {
            setClientId(originalClientId);
        } else {
            setClientId(maskClientId({ target: { value: originalClientId } }));
        }
    };
    
    const toggleClientSecretVisibility = () => {
        setShowClientSecret(!showClientSecret);
        if (!showClientSecret) {
            setClientSecret(originalClientSecret);
        } else {
            setClientSecret(maskClientSecret({ target: { value: originalClientSecret } }));
        }
    };
    
    const toggleWebhookVisibility = () => {
        setShowWebHook(!showWebHook);
        if (!showWebHook) {
            setWebHook(originalWebHook);
        } else {
            setWebHook(maskWebhook({ target: { value: originalWebHook } }));
        }
    };

    const toggleQuickBookIdVisibility = () => {
        setShowQuickBookId(!showQuickBookId);
        if (!showQuickBookId) {
            setQuickBookId(originalQuickBookId);
        } else {
            setQuickBookId(maskQuickBookId({ target: { value: originalQuickBookId } }));
        }
    };
    
    const toggleQuickBookClientSecretVisibility = () => {
        setShowQuickBookSecret(!showQuickBookSecret);
        if (!showQuickBookSecret) {
            setQuickBookSecret(originalQuickBookSecret);
        } else {
            setQuickBookSecret(maskQuickBookSecret({ target: { value: originalQuickBookSecret } }));
        }
    };
    
    const toggleQuickBookWebhookVisibility = () => {
        setShowQuickBookWebHook(!showQuickBookWebHook);
        if (!showQuickBookWebHook) {
            setQuickBookHook(originalQuickBookWebHook);
        } else {
            setQuickBookHook(maskQuickBookWebhook({ target: { value: originalQuickBookWebHook } }));
        }
    };
    const toggleZohoClientIdVisibility = () => {
        setZohoShowClientId(!showZohoClientId);
        if (!showZohoClientId) {
            setZohoClientId(originalZohoClientId);
        } else {
            setZohoClientId(maskZohoClientId({ target: { value: originalZohoClientId } }));
        }
    };
    
    const toggleZohoClientSecretVisibility = () => {
        setZohoShowClientSecret(!showZohoClientSecret);
        if (!showZohoClientSecret) {
            setZohoSecret(originalZohoClientSecret);
        } else {
            setZohoSecret(maskZohoClientSecret({ target: { value: originalZohoClientSecret } }));
        }
    };
    
    const toggleZohoWebhookVisibility = () => {
        setZohoShowWebHook(!showZohoWebHook);
        if (!showZohoWebHook) {
            setZohoWebhook(originalZohoWebHook);
        } else {
            setZohoWebhook(maskZohoWebhook({ target: { value: originalZohoWebHook } }));
        }
    };

    const navigate = useNavigate();

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1);


    const handleOpenZedAccountingModal = () => {
        setOpenZedAccountingModal(true)
    }

    const handleCancelClick = () => {
        setXeroAccountingModal(false);
    };

    const handleCancelQuickBooksClick = () => {
        setQuickBooksModal(false);
    };
    const handleZohoClick = () =>{
        setZohoAccountingModal(false)
    }
    const handleCloseZedAccountingModal = () => {
        // if (zedAccountingStatus === true) {
        //     return;
        // }
        setOpenZedAccountingModal(false);
    }

    const handleIpasCloseModal = () => {
        setOpenIpsasModal(false)
    }

    const handleOpenPayablesModal = () => {
        setOpenPayablesModal(true)
    }
    const handleClosePayablesModal = () => {

    }

    const handleOPenZedPayitModal = () => {
        setOpenZedPayit(true)
    }
    const handleCloseZedPayitModal = () => {
        setMessage('You cannot  disable Payables , if you want to disable it, please contact Zed Payment support')
    }

    const switchZedPayit = () => {
        if (payitStatus) {
            handleCloseZedPayitModal()
        } else if (!payitStatus) {
            handleOPenZedPayitModal()
        }
    }
    const switchPayables = () => {
        if (payablesStatus) {
            handleClosePayablesModal()
        } else if (!payablesStatus) {
            handleOpenPayablesModal()
        }

    }

    const swicthAccounting = () => {
        if (accountStatus) {
            handleCloseZedAccountingModal()
        } else if (!accountStatus) {
            handleOpenZedAccountingModal()
        }

    }

    const swicthAccountingIPSAS = () => {
        if (ipsasStatus) {
            handleIpasCloseModal()
        } else if (!ipsasStatus) {
            setOpenIpsasModal(true)
        }
    }

    //zed accounting persists

    useEffect(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        if (savedStatus) {
            setZedAccountingStatus(JSON.parse(savedStatus));
        }
    }, [])

    const handleOpenConfigurationModal = () => {
        setOpenConfigurationModal(true);

    };
    const handleClosesetConfigurationModal = () => {
        setOpenConfigurationModal(false);


    };

    const [DisableConfigurationInvetory, setDisableConfigurationInvetory] = useState(false);
    const handleOpenDisableConfigurationInvetory = () => {
        setDisableConfigurationInvetory(true);

    };
    const handleCloseDisableConfigurationInvetory = () => {
        setDisableConfigurationInvetory(false);
    };

    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [errorNotificationOpen, setErrorNotificationOpen] = useState(false);
    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };
    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSuccessNotificationOpen(false);
    };
    const handleErrorNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorNotificationOpen(false);
    };
    const [status, setStatus] = useState()

    const [checked, setChecked] = useState();
    const [message, setMessage] = useState('')
    const [stockStatus, setStockStatus] = useState()


    const handleSwithChange = () => {
        if (status) {
            handleOpenDisableConfigurationInvetory()


        } else if (!status) {
            handleOpenConfigurationModal()
            localStorage.setItem("category", "Service")
        }
    };

    // get default dashboard  status
    const [defaultDashboardSchool, setDefaultDashboardSchool] = useState(false)
    const [openDefaultDashboard, setOpenDefaultDashboard] = useState(false)

   // console.log(defaultDashboardSchool , 'defaultDashboardSchool >>>>>>>>>')

    // handle open default dahsboard modal
    const handleOpenDefaultDashbiard = () => {
        setOpenDefaultDashboard(true)
    }
    // handle close default dahsboard modal

    const handleCloseDefaultDashbiard = () => {
        setOpenDefaultDashboard(false)
    }

    function getDefaultSchoolDashboard() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/getDefaultSchooldashboardstatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setDefaultDashboardSchool(data?.response?.data?.hasDefaultSchoolDashbaord)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getDefaultSchoolDashboard()
    }, [openDefaultDashboard])


    const [studentOrderStattus ,setStudentOrderStatus] = useState()
    const [paymentPurchaseSetupTypes, setpaymentPurchaseSetupType] = useState('')
   

    function getStudentOrderStatus(){
        try {
            HttpComponent({
                method:'GET',
                url:`/api/v1/getZedPayItIdentificationConfigs?businessNumber=${businessNumber}`,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
               // console.log(data , 'businessNumber')
                if(data?.status === 200){
                    setStudentOrderStatus(data?.response?.isStudentPurchaseEnabled)
                    setpaymentPurchaseSetupType(data?.response?.paymentPurchaseSetupType)
                }
            })
            
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        getStudentOrderStatus()
    },[businessNumber , state])

    //console.log(studentOrderStattus , 'studentOrderStattus')

    // switch dashboard ==> enable/disable dashboard

    function enableDisableDefaultSchoolDashboard() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enableordisableDefaultSchooldashboardstatus',
                body: null,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setDefaultDashboardSchool(data?.response?.data?.hasDefaultSchoolDashbaord)
                    setSuccessShow({ state: true, message:data?.response?.data?.hasDefaultSchoolDashbaord ? 'You have successfully enabled zed pocket money as default dashboard' : 'You have no default dashboard set' })
                    setTimeout(()=>{
                        handleCloseDefaultDashbiard()
                    } , 1000)
                    
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error?.message })
        }
    }

    const handleXeroSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await HttpComponent({
                method: 'post',
                url: '/api/v1/business/config/xero',
                body: {
                    clientId: originalClientId,
                    clientSecret: originalClientSecret,
                    webHookKey: originalWebHook
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                // console.log(data);
                if (data.response.Status === "SUCCESS") {
                    // localStorage.setItem('clientId', originalClientId);
                    // localStorage.setItem('clientSecret', originalClientSecret);
                    // localStorage.setItem('webHook', originalWebHook);
    
                    setMessage('Xero Accounting enabled successfully, click xeroAccounting icon to proceed');
                    handleSuccessNotificationClick();
                    window.location.assign(data.response.redirect_url); 
                } else {
                    console.error('Error enabling Xero Accounting:',);
                    setMessage('Failed to enable Xero Accounting');
                }
            });
        } catch (error) {
            // Handle the error
        }
    };

    const handleQuickBooksSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await HttpComponent({
                method: 'post',
                url: '/api/v1/quickbooks/get_quikbooks_token',
                body: {
                    clientId: originalQuickBookId,
                    clientSecret: originalQuickBookSecret,
                    webHookKey: originalQuickBookWebHook,
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) =>{
                // console.log(data.response.Status);
                if (data.response.Status === "SUCCESS"){
                    setMessage('QuickBooks Enabled Successfully!');
                    handleSuccessNotificationClick();
                    window.location.assign(data.response.redirect_url); 
                } else {
                    console.error('Error enabling QuickBooks Account',)
                    setMessage('Failed to Enable QucikBooks Accounting')
                }
            });
            }
            catch(error) {
                setMessage('Failed to Enable QucikBooks Accounting')
            }
    };
    const handleZohoSubmit = async(event) =>{
        event.preventDefault();
        try {
            const response = await HttpComponent({
                method: 'post',
                url: '/api/v1/zoho/auth/zoho_config',
                body: {
                    clientId: originalZohoClientId,
                    clientSecret: originalZohoClientSecret,
                    webHookKey: originalZohoWebHook
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                console.log(data);
                if (data.response.Status === "SUCCESS") {
                    setMessage('Zoho Accounting enabled successfully');
                    handleSuccessNotificationClick();
                    window.location.assign(data.response.redirect_url); 
                } else {
                    console.error('Error enabling zoho Accounting:', error);
                    setMessage('Failed to enable zoho Accounting');
                }
            });
        } catch (error) {
            // Handle the error
        }
        
    }

    useEffect(() => {
        const storedClientId = localStorage.getItem('clientId'); 
        const storedClientSecret = localStorage.getItem('clientSecret'); 
        const storedWebHook = localStorage.getItem('webHookKey');
        const storedClientQuickBookId = localStorage.getItem('clientIdQuick');
        const storedClientSecretQuickBook = localStorage.getItem('clientSecretQuick');
        const storedWebHookQuickBook = localStorage.getItem('webHookKeyQuick');
        const zohoId = localStorage.getItem('zhClientId'); 
        const zohoSecret = localStorage.getItem('zhSecret'); 
        const zohoWebHook = localStorage.getItem('zhWebhookKey'); 
        const isValid = (value) => value && value !== 'undefined';



        const maskedClientId = isValid(storedClientId) ? maskClientId({ target: { value: storedClientId } }) : '';
        const maskedClientSecret = isValid(storedClientSecret) ? maskClientSecret({ target: { value: storedClientSecret } }) : '';
        const maskedWebhook = isValid(storedWebHook) ? maskWebhook({ target: { value: storedWebHook } }) : '';

        const maskedClientIdQuickBook = isValid(storedClientQuickBookId) ? maskQuickBookId({ target: { value: storedClientQuickBookId } }) : '';
        const maskedClientSecretQuickBook = isValid(storedClientSecretQuickBook) ? maskQuickBookSecret({ target: { value: storedClientSecretQuickBook } }) : '';
        const maskedWebhookQuickBook = isValid(storedWebHookQuickBook) ? maskQuickBookWebhook({ target: { value: storedWebHookQuickBook } }) : '';

        const maskedZohoClientId = isValid(zohoId) ? maskClientId({ target: { value: zohoId } }) : '';
        const maskedZohoClientSecret = isValid(zohoSecret) ? maskClientSecret({ target: { value: zohoSecret } }) : '';
        const maskedZohoWebhook = isValid(zohoWebHook) ? maskWebhook({ target: { value: zohoWebHook } }) : '';


        setClientId(maskedClientId);
        setOriginalClientId(storedClientId || '');
        setClientSecret(maskedClientSecret);
        setOriginalClientSecret(storedClientSecret || '');
        setWebHook(maskedWebhook);
        setOriginalWebHook(storedWebHook || '');
        setQuickBookId(maskedClientIdQuickBook);
        setOriginalQuickBookId(storedClientQuickBookId || '');
        setQuickBookSecret(maskedClientSecretQuickBook);
        setOriginalQuickBookSecret(storedClientSecretQuickBook || '');
        setQuickBookHook(maskedWebhookQuickBook);
        setOriginalQuickBookWebHook(storedWebHookQuickBook || '');
        setZohoClientId(maskedZohoClientId|| '')
        setOriginalZohoClientId(zohoId || '')
        setZohoSecret(maskedZohoClientSecret || '')
        setOriginalZohoClientSecret(zohoSecret || '')
        setZohoWebhook(maskedZohoWebhook || '')
        setOriginalZohoWebHook(zohoWebHook || '')
    }, []);  

    //get enable autogenerate students adm
    const [autogenerateAdmstatus, setAutogenerateAdmstatus] = useState(false)

    function getAutogenerateAdmnumberStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/students/setup/getAutogenerateStudentNumberStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setAutogenerateAdmstatus(data?.response?.data?.autogenerateStudentNumber)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAutogenerateAdmnumberStatus()
    }, [])


    // const handleMoveConfig = ()=> 


    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data?.response?.data?.accountingEnabled)
                    setIpsasStatus(data?.response?.data?.ipsasEnabled)
                    setXeroStatus(data?.response?.data?.xeroAccountingEnabled)
                    setWebHookStatus(data?.response?.data?.xeroWebHookIntentStatus)
                    setQuickBooksStatus(data?.response?.data?.quickbooksEnabled)
                    setZohoStatus(data?.response?.data?.zohoAccountingEnabled)
                }
            })
        } catch (error) {
        }
    }


    function getPayitStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/getZedPayItStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                console.log(data, 'payit status')
                if (data.status === 200) {
                    setPayitStatus(data?.response?.data)
                }
            })
        } catch (error) {
        }
    }

    function getPayablesStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/business/payables/status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                // console.log(data, 'payables status')
                if (data.status === 200) {
                    console.log(data?.response?.data.payablesStatus, 'payables status')
                    let statusPayables = data?.response?.data?.payablesStatus
                    setPayablesStatus(JSON.parse(statusPayables));
                }
            })
        } catch (error) {
            console.log(error)

        }
    }



    useEffect(() => {
        getPayitStatus()
    }, [])

    useEffect(() => {
        getAccountingStatus()
    }, [])


    //enable/disable getting status
    useEffect(() => {
        getPayablesStatus()
    }, [])



    // EnableWare House 
    const EnableWareHouseConfiguration = async () => {
        // console.log('i am here');
        let obj = {}
        if (status) {
            obj = { enabled: false }
        } else if (!status) {
            obj = { enabled: true }
        }

        try {
            const reposonse = await fetch(`${baseUrl}/api/v1/stores/enable_inventory`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify(obj)

            })
            const data = reposonse.json()
                .then((response) => {
                    console.log(response);
                    setMessage(obj.enabled ? 'Inventory Enabled Successfully' : 'Inventory Disabled Successfully')
                    handleSuccessNotificationClick();
                    obj.enabled ? handleClosesetConfigurationModal() : handleCloseDisableConfigurationInvetory()

                    setTimeout(() => {
                        getWareHouseStatus()
                        window.location.reload()
                    }, [1000])
                })


        } catch (err) {

        }
    }
    const getWareHouseStatus = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),

                },
            })
            const data = response.json()
                .then((response) => {
                    console.log('response', response.data.enabled);
                    setStatus(response?.data?.enabled)
                })

        } catch (error) {

        }


    }

    useEffect(() => {
        getWareHouseStatus()

    }, [])

    // ENABLE STOCK IN ZED
    const [stockConfigMap, setStockConfigMap] = useState([])
    const [switchState, setSwitchState] = useState('')
    const handleSwitchClick = (index) => {
        const newItems = [...stockConfigMap];
        newItems[index].isSelected = !newItems[index].isSelected;
        setStockConfigMap(newItems);
    };

    const handleStockConfigUpdate = async (index) => {
        try {
            const response = await fetch(baseUrl + `/api/update_branch_stock_option`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization")
                },
                body: JSON.stringify({ stockOptionId: stockConfigMap[index].configName })
            });
            await response.json()
                .then((data) => {
                    fetchStockConfigs()
                })

        } catch (err) {
            console.error(err);
        }
    };

    const fetchStockConfigs = async () => {
        try {
            const response = await fetch(baseUrl + '/api/get_branch_stock_options_list', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization")
                }
            })
            await response.json()
                .then((data) => {
                    const stockMap = data.data.map((config) => {
                        return {
                            id: config.id,
                            configName: config.name,
                            isSelected: config.isSelected
                        }
                    })
                    setStockConfigMap(stockMap)
                })
                .catch((err) => { console.log('error getting stocks', err.message) })
        } catch (e) { console.log(e.message) }
    }

    useEffect(() => {
        fetchStockConfigs()
    }, [])



    //handle enable accounting

    //enable accounting
    function handleEnableAccounting() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enable_accounting?status=true',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async (data) => {
                //console.log(data, 'acc status')
                if (data.status === 201) {
                    setMessage('Zed Accounting enabled successfully , click zedAccounting icon to proceed')
                    handleSuccessNotificationClick();
                    setZedAccountingStatus(data.response.data.accountingEnabled)
                    localStorage.setItem('zedAccountingStatus', true);
                }
            }).then(async () => {
                const newT = await HttpComponent({
                    method: "POST",
                    url: `/api/get_token_after_invite`,
                    token: localStorage.getItem('X-Authorization')
                })
                const result = newT?.response?.data?.token
                const decodedT = jwtDecode(result)
                localStorage.setItem('X-Authorization', result);
                localStorage.setItem('zedAccountingId', decodedT?.zedAccountingId);
                setTimeout(() => {
                    handleCloseZedAccountingModal()
                    window.location.reload()
                }, 1000);
            })
        } catch (error) {

        }
    }

    //enable accounting IPSAS
    function handleEnableAccountingIPSAS() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enable_accounting?status=true',
                body: { accountingType: "IPSAS" },
                token: localStorage.getItem('X-Authorization')
            }).then(async (data) => {
                console.log(data, 'acc status')
                if (data.status === 201) {
                    setMessage('Zed Accounting IPSAS enabled successfully , click zedAccounting icon to proceed')
                    handleSuccessNotificationClick();
                    setOpenIpsasModal(false)
                    setZedAccountingStatus(data.response.data.accountingEnabled)
                    localStorage.setItem('zedAccountingStatus', true);
                    const newT = await HttpComponent({
                        method: "POST",
                        url: `/api/get_token_after_invite`,
                        token: localStorage.getItem('X-Authorization')
                    })
                    const result = newT?.response?.data?.token
                    const decodedT = jwtDecode(result)
                    localStorage.setItem('X-Authorization', result);
                    localStorage.setItem('zedAccountingId', decodedT?.zedAccountingId);
                    setTimeout(() => {
                        handleCloseZedAccountingModal()
                        window.location.reload()
                    }, 1000);
                    
                }else {
                    setErrorShow({ state: true, message: data?.response?.message })
                    setOpenIpsasModal(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                }
            })
            
        } catch (error) {

        }
    }

    //enable zed payit
    function handleEnableZedPayit() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enabaleZedpayItConfig',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async (data) => {
                if (data.status === 201) {
                    await stockfunctions.getWarehouseStatus().then((data) => {
                        if (data?.status === 201) {
                            console.log("data?.response?.data?.warehouseOn", data?.response?.data?.warehouseOn)
                            dispatch(switchWarehouseState(data?.response?.data?.warehouseOn))
                        }
                    })
                    await HttpComponent({
                        method: "POST",
                        url: `/api/get_token_after_invite`,
                        token: localStorage.getItem('X-Authorization')
                    }).then((data) => {
                        console.log(data, 'new token')
                    })
                }
                if (data.status === 201) {
                    setMessage('Zed Pocket Money  enabled successfully , click Zed Pocket Money menu  to proceed')
                    handleSuccessNotificationClick();
                    setZedPayitStatus(data?.response?.data)
                    setTimeout(() => {
                        handleCloseZedPayitModal()
                        window.location.reload()

                    }, [1000]);
                }
            })
        } catch (error) {

        }
    }


    // enable stock in sale!
    const upDateStockConfigs = (stock) => {
        switch (stock?.id) {
            case "No Stock":
                HttpComponent({
                    method: "POST",
                    url: `/api/update_branch_stock_option`,
                    body: { stockOptionId: "No Stock" },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then(() => {
                        HttpComponent({
                            method: "POST",
                            url: `/api/updateBranchStockNegativeStatus`,
                            body: { status: false },
                            token: localStorage.getItem("X-Authorization"),
                        }).then(() => fetchStockConfigs())

                        HttpComponent({
                            method: "POST",
                            url: `/api/update_supervisor_can_do_return`,
                            body: { status: false },
                            token: localStorage.getItem("X-Authorization"),
                        }).then(() => fetchStockConfigs())

                        HttpComponent({
                            method: "POST",
                            url: `/api/update_show_buying_price_in_sale_status`,
                            body: { showBuyingPriceInSale: false },
                            token: localStorage.getItem("X-Authorization"),
                        }).then(() => fetchStockConfigs())
                    })
                    .catch((e) => console.log(e.message));
                break;

            case "Stock In Zed":
                HttpComponent({
                    method: "POST",
                    url: `/api/update_branch_stock_option`,
                    body: { stockOptionId: "Stock In Zed" },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            case "Enable Stock Report Config":
                if (stock.isSelected) {
                    const startTime = new Date();
                    startTime.setHours(0, 0, 0, 0);
                    const endTime = new Date();
                    endTime.setHours(23, 59, 59, 0);
                    HttpComponent({
                    method: "POST",
                    url: `/api/v1/business/enable/stock`,
                    body: { status: true, startTime, endTime },
                    token: localStorage.getItem("X-Authorization"),
                    }).then(() => fetchStockConfigs());
                } else {
                    HttpComponent({
                    method: "POST",
                    url: `/api/v1/business/enable/stock`,
                    body: { status: false },
                    token: localStorage.getItem("X-Authorization"),
                    }).then(() => fetchStockConfigs());
                }
                break;
            case "Stock in Sale":
                HttpComponent({
                    method: "POST",
                    url: `/api/update_branch_stock_in_sale_status`,
                    body: { showStockInSale: stock.isSelected },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => {
                        fetchStockConfigs();
                    })
                    .catch((e) => {
                        console.log(e.message);
                    });
                break;
            case "Buying price In Sale":
                const noStockBuying = [...stockConfigMap]
                const noStockBuyingValue = noStockBuying[0]?.isSelected
                HttpComponent({
                    method: "POST",
                    url: `/api/update_show_buying_price_in_sale_status`,
                    body: { showBuyingPriceInSale: noStockBuyingValue === true ? false : true },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            case "Allow Negative Order":
                const newStock = [...stockConfigMap]
                const noStockValue = newStock[0]?.isSelected
                HttpComponent({
                    method: "POST",
                    url: `/api/updateBranchStockNegativeStatus`,
                    body: { status: noStockValue === true ? false : true },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            case "Enable Return by Supervisor":
                const noStockReturn = [...stockConfigMap]
                const noStockReturnValue = noStockReturn[0]?.isSelected
                HttpComponent({
                    method: "POST",
                    url: `/api/update_supervisor_can_do_return`,
                    body: { status: noStockReturnValue === true ? false : true },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            default:
                break;
        }
    };


    // enable self ordering

    function handleenableSelfOrder(){
        try {
            HttpComponent({
                method:'POST',
                url:`/api/v1/enableZedPayIdentification`,
                body:{
                    isStudentPurchaseEnabled:true
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({state:true , message:data?.response?.message})
                    setOpenEnableSelfOrdering(false)
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                    setOpenEnableSelfOrdering(false)
                }

                setTimeout(()=>{
                    navigate(`/school/configuration?studentViewOrder`)
                },1000)
            })
            
        } catch (error) {
            
        }

    }


    // Enable / Disable Payables
    const updatePayablesStatus = async () => {
        try {
            const reposonse = await fetch(`${baseUrl}/api/v1/business/payables/enable_disable`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({ status: true })

            })

            const data = reposonse.json()
                .then((response) => {
                    console.log(response);
                    if (response.status === "SUCCESS") {
                        setMessage('Payables Enabled Successfully')
                        handleSuccessNotificationClick();
                        getPayablesStatus()
                        setOpenPayablesModal(false)
                        window.location.reload()


                    } else {
                        setMessage(response?.message)
                        handleErrorNotificationClick()
                        setOpenPayablesModal(false)


                    }
                })



        } catch (error) {

        }

    }

    return (
        <Box>
            {state === 'configadm' ? <SchoolGradeAutogenerateConfigSetUp /> : state === 'studentOrder' ? <StudentOrderConfig/> : state === 'studentViewOrder' ? <StudentOrderConfigSave/>: <>
                <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert message={message} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Box> {openXeroAccountingModal || openQuickBooks || openZohoAccountingModal ? '' :<Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Configurations</Box>}</Box>
                {openXeroAccountingModal || openQuickBooks || openZohoAccountingModal ? '' :<Box>
                    <Box>
                        <Box>
                            <Box component="Box" sx={{}}>
                                <Breadcrumbs
                                    separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                                    aria-label="breadcrumb">
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Box>

                            <Box style={{ marginTop: "20px" }}>
                                <CustomSearchInput />
                            </Box>

                            <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "545px" }}>
                                <Box style={{ display: "flex", justifyContent: "" }}>
                                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Inventory</Box>
                                    <Box style={{ marginLeft: "auto" }}>
                                        <Switch checked={status} onClick={handleSwithChange} />
                                    </Box>
                                </Box>
                            </Box>


                            {/* The other configurations */}
                            {status ?
                                <>
                                    <List style={{ width: "547.5px" }}>
                                        {
                                            stockConfigMap.map((stock, index) => {
                                                return (
                                                    <ListItem key={stock.id} style={{ marginBottom: '10px', fontFamily: "Poppins", fontSize: '14px', "borderRadius": "6px", "border": "solid 1px rgba(193,193,193, .3)" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                                                            <div style={{ paddingTop: '10px', "fontFamily": "Poppins", "fontSize": "15px", "color": "#032541" }} >{stock.configName}</div>
                                                            <div ><Switch checked={stock.isSelected} onClick={() => {
                                                                handleSwitchClick(index)
                                                                upDateStockConfigs(stock);
                                                            }

                                                            } />
                                                            </div>
                                                        </div>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>

                                </>
                                : null}


                        </Box>
                        <Box onClick={() => navigate('/school/configuration?configadm')} style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Auto Generate Student Number</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={autogenerateAdmstatus} />
                                </Box>
                            </Box>
                        </Box>

                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable ZED Accounting</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={accountStatus} onClick={swicthAccounting} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Accounting for IPSAS</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={ipsasStatus} onClick={swicthAccountingIPSAS} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable XERO Accounting</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={xeroStatus} onClick= {() => setXeroAccountingModal(true)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable QuickBooks Accounting</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={quickBooksStatus} onClick= {() => setQuickBooksModal(true)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Zoho Accounting</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={zohoStatus} onClick= {() => setZohoAccountingModal(true)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Zed Pocket Money</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={payitStatus} onClick={switchZedPayit} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable  Payables</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={payablesStatus} onClick={switchPayables} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Default Dashboard: Zed Pocket Money</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={defaultDashboardSchool} onClick={handleOpenDefaultDashbiard} />
                                </Box>
                            </Box>
                        </Box>
                        {schoolTypeName === 'University/College' ? 
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Student Self Ordering</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    {studentOrderStattus  ? <Typography onClick={()=>navigate(`/school/configuration?studentOrder`)} style={{color:'#17ae7b' , cursor:'pointer'}}>Enabled</Typography>: <Typography onClick={handleOpenseforderModal} style={{color:'#dc3545' , cursor:'pointer'}}>Click to Enable</Typography>}
                                </Box>
                            </Box>
                        </Box>: null
                        }
                        {schoolTypeName === 'University/College' && studentOrderStattus === true  ?
                            <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Purchase Account Type</Box>
                                        <Box style={{ marginLeft: "auto" }}>
                                         <Typography onClick={handleOpenseforderModal} style={{ cursor: 'pointer' }}>{paymentPurchaseSetupTypes}</Typography>
                                        </Box>
                                    </Box> 
                            </Box>
                            : null}
                    </Box>
                </Box>
                }
                <Modal
                    open={OpenConfigurationModal} onClose={handleClosesetConfigurationModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={style}>
                        <form onSubmit={EnableWareHouseConfiguration}>

                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box>
                                    <img src={EnableBigIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />

                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Inventory in School?</Box>
                                    <Box>
                                        <p style={{ fontSize: "14px", color: "#707070" }}>
                                            Enabling inventory in school will allow the business to track and manage products, stock, stores, warehouses and customers.
                                        </p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <img alt="" src={EnableSmallIcon} style={{ height: "25px", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your inventory, products and services.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <img alt="" src={warehouseIcon} style={{ height: "25px", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your suppliers, stores and warehouses.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <img alt="" src={userIcon} style={{ height: "25px", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your customer accounts, activity, invoices, receipts and statements.</p>
                                    </Box>

                                    <Box style={{ display: "flex", marginTop: "15px" }}>

                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                handleClosesetConfigurationModal

                                            }>Cancel</Button>
                                        </Box>
                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                onClick={() => {
                                                    EnableWareHouseConfiguration()
                                                    console.log('EnableWareHouseConfiguration');
                                                }}>Enable</Button>
                                        </Box>

                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Modal>
                {openXeroAccountingModal &&
                    <>
                        <IconButton style={{  top: '40px', right: '1px', left:'5px'  }} onClick={handleCancelClick}>
                            <ArrowBackIos />
                        </IconButton>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box style={{ marginLeft: "35px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Enable Xero Accounting</Box>
                                <Box>
                                    <p style={{ fontSize: '14px', color: "#707070", width: '475px', height: '41px', margin: '23px 599px 20px 3px' }}>
                                        Enabling Xero accounting will allow the business to manage financial transactions.
                                    </p>
                                </Box>
                                <ul style={{ listStyleType: "none", padding: 0, marginLeft: 0 }}>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements.</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare Budget Forecasts</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish Financial statements in time</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", display: "inline-block", fontSize: '24px', width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile Accounts Payable and receivables</p>
                                    </li>
                                </ul>
                                <form onSubmit={handleXeroSubmit}>
                                    <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                        <label htmlFor="clientId" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client ID:<span style={{ color: 'red' }}>*</span></label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="text" id="clientId" name="clientId" placeholder="Client ID" value={clientId} onChange={maskClientId} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                            <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleClientIdVisibility}>
                                                {showClientId ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </div>
                                    </Box>

                                    <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                        <label htmlFor="clientSecret" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client Secret Key<span style={{ color: 'red' }}>*</span></label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="text" id="clientSecret" name="clientSecret" placeholder="Client Secret" value={clientSecret} onChange={maskClientSecret} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px", paddingRight: "40px" }} />
                                            <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleClientSecretVisibility}>
                                                {showClientSecret ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </div>
                                    </Box>

                                    <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                        <label htmlFor="webhook" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Webhook Key<span style={{ color: 'red' }}>*</span></label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="text" id="webhook" name="webhook" placeholder="WebHook Key" value={webHook} onChange={maskWebhook} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                            <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleWebhookVisibility}>
                                                {showWebHook ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </div>
                                    </Box>

                                    <Box style={{ display: "flex", marginTop: "15px" }}>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelClick}>Cancel</Button>
                                        <Box marginLeft="20px">
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Enable</Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </>
                }
                {openQuickBooks &&
                    <>
                        <IconButton style={{ top: '40px', right: '1px', left:'5px' }} onClick={handleCancelQuickBooksClick}>
                            <ArrowBackIos />
                        </IconButton>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box style={{ marginLeft: "35px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>QuickBooks</Box>
                                <Box>
                                    <p style={{ fontSize: '14px', color: "#707070", width: '662px', height: '41px', margin: '23px 599px 20px 3px' }}>
                                        Enabling QuickBooks It allows you to run and view reports easily like the number of outstanding invoices, total amount due from clients and details of each invoice (paid or due).
                                    </p>
                                </Box>
                                <ul style={{ listStyleType: "none", padding: 0, marginLeft: 0 }}>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Keep track of financial functions like income and expenses.</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Bills and Expenses Tracking.</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Integrates well with other systems and flexible with 3rd party applications.</p>
                                    </li>
                                    <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <span style={{ marginLeft: "10px", color: "#032541", display: "inline-block", fontSize: '24px', width: "10px" }}>•</span>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile Accounts Payable and receivables</p>
                                    </li>
                                </ul>
                                <form onSubmit={handleQuickBooksSubmit}>
                                    <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                        <label htmlFor="clientId" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client ID:<span style={{ color: 'red' }}>*</span></label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="text" id="quickBookId" name="clientId" placeholder="Client ID" value={quickBookId} onChange={maskQuickBookId} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                            <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleQuickBookIdVisibility}>
                                                {showClientId ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </div>
                                    </Box>

                                    <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                        <label htmlFor="clientSecret" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client Secret Key<span style={{ color: 'red' }}>*</span></label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="text" id="quickBookSecret" name="clientSecret" placeholder="Client Secret" value={quickBookSecret} onChange={maskQuickBookSecret} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px", paddingRight: "40px" }} />
                                            <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleQuickBookClientSecretVisibility}>
                                                {showClientSecret ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </div>
                                    </Box>

                                    <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                        <label htmlFor="webhook" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Webhook Key<span style={{ color: 'red' }}>*</span></label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input type="text" id="quickBookHook" name="webhook" placeholder="WebHook Key" value={quickBookHook} onChange={maskQuickBookWebhook} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                            <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleQuickBookWebhookVisibility}>
                                                {showWebHook ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </div>
                                    </Box>

                                    <Box style={{ display: "flex", marginTop: "15px" }}>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelQuickBooksClick}>Cancel</Button>
                                        <Box marginLeft="20px">
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Enable</Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </>
                }
                 {openZohoAccountingModal &&
               <>
               <IconButton style={{  top: '40px', right: '1px', left:'5px' }} onClick={handleCancelClick}>
                   <ArrowBackIos />
               </IconButton>
               <Box style={{ display: "flex", justifyContent: "" }}>
                   <Box style={{ marginLeft: "35px" }}>
                       <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Enable Zoho Accounting</Box>
                       <Box>
                           <p style={{ fontSize: '14px', color: "#707070", width: '475px', height: '41px', margin: '23px 599px 20px 3px' }}>
                               Enabling Zoho accounting will allow the business to manage financial transactions.
                           </p>
                       </Box>
                       <ul style={{ listStyleType: "none", padding: 0, marginLeft: 0 }}>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements.</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare Budget Forecasts</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish Financial statements in time</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", display: "inline-block", fontSize: '24px', width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile Accounts Payable and receivables</p>
                           </li>
                       </ul>
                       <form onSubmit={handleZohoSubmit}>
                           <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                               <label htmlFor="clientId" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client ID:<span style={{ color: 'red' }}>*</span></label>
                               <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <input type="text" id="zohoClientId" name="zohoClientId" onChange={maskZohoClientId} placeholder="Client ID" value={zohoClientId} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                   <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleZohoClientIdVisibility}>
                                       {showZohoClientId ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </div>
                           </Box>

                           <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                               <label htmlFor="clientSecret" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client Secret Key<span style={{ color: 'red' }}>*</span></label>
                               <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <input type="text" id="zohoClientSecret" name="zohoClientSecret" placeholder="Client Secret" value={zohoClientSecret} onChange={maskZohoClientSecret} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px", paddingRight: "40px" }} />
                                   <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleZohoClientSecretVisibility}>
                                       {showZohoClientSecret ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </div>
                           </Box>

                           <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                               <label htmlFor="webhook" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Webhook Key<span style={{ color: 'red' }}>*</span></label>
                               <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <input type="text" id="zohoWebhookKey" name="zohoWebhookKey" placeholder="WebHook Key" value={zohoWebhookKey} onChange={maskZohoWebhook} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                   <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleZohoWebhookVisibility}>
                                       {showZohoWebHook ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </div>
                           </Box>

                           <Box style={{ display: "flex", marginTop: "15px" }}>
                               <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelClick}>Cancel</Button>
                               <Box marginLeft="20px">
                                   <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Enable</Button>
                               </Box>
                           </Box>
                       </form>
                   </Box>
               </Box>
           </>
            }
                <Modal
                    open={DisableConfigurationInvetory} onClose={handleCloseDisableConfigurationInvetory} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={style}>
                        <form onSubmit={EnableWareHouseConfiguration}>

                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box>
                                    <img src={EnableBigIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />

                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Disable Inventory in School?</Box>
                                    <Box>
                                        <p style={{ fontSize: "14px", color: "#707070" }}>
                                            Disabling inventory in school will not allow the business to track and manage products, stock, stores, warehouses and customers.
                                        </p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <img src={EnableSmallIcon} style={{ height: "25px", width: "25px" }} alt="" />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your inventory, products and services.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <img src={warehouseIcon} style={{ height: "25px", width: "25px" }} alt="" />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your suppliers, stores and warehouses.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <img src={userIcon} style={{ height: "25px", width: "25px" }} alt="" />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your customer accounts, activity, invoices, receipts and statements.</p>
                                    </Box>

                                    <Box style={{ display: "flex", marginTop: "15px" }}>

                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                handleCloseDisableConfigurationInvetory

                                            }>Cancel</Button>
                                        </Box>
                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                onClick={() => {
                                                    EnableWareHouseConfiguration()
                                                    console.log('DISEnableWareHouseConfiguration');
                                                }}>Disable</Button>
                                        </Box>

                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={openZedAccountingModal} onClose={handleCloseZedAccountingModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={zedAccountingmodal}>
                        <form>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box>
                                    <img src={ZedaccImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Accounting </Box>
                                    <Box>
                                        <p style={{ fontSize: "14px", color: "#707070" }}>
                                            Enabling accounting  will allow the business to:
                                        </p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Manage financial transactions</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare budget forecasts.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements..</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish financial statements in time.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile accounts payable and receivable..</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                handleCloseZedAccountingModal

                                            }>Cancel</Button>
                                        </Box>
                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                onClick={handleEnableAccounting}>Enable</Button>
                                        </Box>

                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Modal>

                {/* IPSAS MODAL  */}
                <Modal
                    open={openIpsasModal} onClose={handleIpasCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={zedAccountingmodal}>
                        <form>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box>
                                    <img src={ZedaccImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Accounting IPSAS </Box>
                                    <Box>
                                        <p style={{ fontSize: "14px", color: "#707070" }}>
                                            Enabling accounting  for IPSAS will allow the business to manage Reports and Financial Statements: 
                                        </p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Manage Statements of compliance & basis of preparation</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage  in-Kind Contribution.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare budget forecasts.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Recognition of Receipts & Payments.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile accounts payables and receivable.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage Cash & Cash Equivalents.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Non-Current assets.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Comparativ Figures</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                handleIpasCloseModal

                                            }>Cancel</Button>
                                        </Box>
                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                onClick={handleEnableAccountingIPSAS}>Enable</Button>
                                        </Box>

                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={openZedPayit} onClose={handleCloseZedPayitModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={zedAccountingmodal}>
                        <form>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box>
                                    <img src={PayitImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Zed Pocket Money ? </Box>
                                    <Box>
                                        <p style={{ fontSize: "14px", color: "#707070" }}>
                                            Enabling Zed Pocket Money in school  will display the Zed Pocket Money menu on the navbar:
                                        </p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Terminal users</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Terminals.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>

                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Device models.</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "0px" }}>
                                        <Box>
                                            <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                        </Box>
                                        <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Outlets</p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                handleCloseZedAccountingModal

                                            }>Cancel</Button>
                                        </Box>
                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                onClick={handleEnableZedPayit}>Enable</Button>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Modal>

                <Modal
                    open={openDefaultDashboard} onClose={handleCloseDefaultDashbiard} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={zedDashboardmodal}>
                        <Grid container height={'100%'} display={'flex'} direction={'column'} justifyContent={'center'}>
                            <Grid item>
                                {defaultDashboardSchool === true ? <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", display: 'flex', justifyContent: 'center' }}>Disable Zed Pocket Money as default dashoard ? </Box> : <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", display: 'flex', justifyContent: 'center' }}>Enable Zed Pocket Money as default dashboard  ? </Box>}
                            </Grid>
                            <Grid item display={'flex'} mt={10} justifyContent={'space-evenly'} alignItems={'center'}>
                                <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                    handleCloseDefaultDashbiard
                                }>Cancel</Button>
                                <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                    onClick={enableDisableDefaultSchoolDashboard}>{defaultDashboardSchool === true ? 'Disable' : 'Enable' }</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                <Modal
                    open={openEnableSelfOrdering} onClose={handleClosenseforderModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={zedDashboardmodal}>
                        <Grid container height={'100%'} spacing={2} display={'flex'} alignContent={'center'} justifyContent={'center'}>
                            <Grid item display={'flex'} width={'40%'}>
                                <img src={SelfOrderPng} alt="" />
                            </Grid>
                            <Grid item display={'flex'} width={'60%'}>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item mb={2}>
                                        <Typography sx={{color:'#032541' , fontWeight:600 , fontSize:'16px'}}>Enable Student Self Ordering ?</Typography>
                                    </Grid>
                                    <Grid item mt={1}>
                                        <Typography sx={{color:'#707070' , fontWeight:'normal', fontSize:'14px'}}>Enabling Student Self Ordering will allow students to place orders and pay for them by themselves</Typography>
                                    </Grid>
                                    <Grid item mt={1} mb={1}>
                                        <Typography  sx={{color:'#707070' , fontWeight:'normal', fontSize:'14px'}}>Do you want to proceed?</Typography>
                                    </Grid>
                                    <Grid item mt={2} display={'flex'} justifyContent={'space-between'}>
                                        <Button onClick={handleClosenseforderModal} style={{ height: "45px", width: "125px", borderRadius: "4px", border: "solid 1px #dc3545", color: "#dc3545" , textTransform:'inherit' }}>Cancel</Button>
                                        <Button onClick={handleenableSelfOrder} style={{ height: "45px", width: "125px", borderRadius: "4px", backgroundColor: "#032541", color: "#fff" , textTransform:'inherit'}}> Proceed</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                <Modal
                    open={openPayablesModal} onClose={handleOpenPayablesModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                    <Box sx={zedAccountingmodal}>
                        <form>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box>
                                    <img src={PayablesIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Payables? </Box>
                                    <Box>
                                        <p style={{ fontSize: "14px", color: "#707070", marginTop: "10px" }}>
                                            Enabling payables will allow your business to pay suppliers, vendors and track internal payments through petty cash.
                                        </p>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "10px" }}>
                                        <Box style={{ display: "flex" }}>
                                            <div className="image-container">
                                                <img src={PayablesIcon1} style={{ height: "25px", width: "25px" }} alt="" />
                                            </div>
                                            <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                                                <p>Assign one or more approvers to transactions <br /> made from the business.</p>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "" }}>
                                        <Box style={{ display: "flex" }}>
                                            <div className="image-container">
                                                <img src={PayablesIcon2} style={{ height: "25px", width: "25px" }} alt="" />
                                            </div>
                                            <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                                                <p>Pay directly to suppliers and vendors from the app.</p>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "" }}>
                                        <Box style={{ display: "flex" }}>
                                            <div className="image-container">
                                                <img src={PayablesIcon3} style={{ height: "25px", width: "25px" }} alt="" />
                                            </div>
                                            <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                                                <p>Make petty cash payments and track them.</p>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                        <Box>
                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                handleClosePayablesModal

                                            }>Cancel</Button>
                                        </Box>
                                        <Box>


                                            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                onClick={updatePayablesStatus}>Enable</Button>

                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Modal>
            </>
            }

        </Box>
    )
}

export default SchoolConfigurationEnable