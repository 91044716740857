import { Box, Breadcrumbs, Button, Divider, Grid, List, Tab, Table, TableBody, TableCell, TableRow, TableHead, Typography, IconButton, TextField, Dialog, DialogContent, DialogTitle, } from "@mui/material";
import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { addPayable, clearPayable } from "../../../features/payableslice";
import { useSelector, useDispatch } from 'react-redux';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { makeStyles } from "@mui/styles";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../../../components/customerAccounts/customerComponents/invoice.css";
import PrintIcon from '@mui/icons-material/Print';
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import CustomSelect from "../../School/CustomSelectField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import html2pdf from 'html2pdf.js';
import CloseIcon from '@mui/icons-material/Close';
const moment = require('moment')


const useStyles = makeStyles((theme) => ({
  address_text_common: {
    fontSize: "13px",
    fontWeight: "300",
  },
  table_titles: {
    fontSize: "14px",
    fontWeight: "700",
  },
  table_body: {
    fontSize: "13px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  footer_text: {
    fontSize: "10px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  totals: {
    fontSize: "14px",
    padding: theme.spacing(0.25),
  },
  p1: {
    padding: theme.spacing(0.25),
  },
  p2: {
    padding: theme.spacing(0.5),
  },
  p3: {
    padding: theme.spacing(1),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  m1: {
    margin: theme.spacing(1),
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
}));

const AddProductModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "520px",
  height: "665px",
  border: "solid 1px #707070",
  padding: "40px",

};
const breadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>,
  <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Preview Transaction</Typography>
]
const reconcilebreadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>,
  <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Preview Transaction</Typography>,
  <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Reconcile Transaction</Typography>
]
const baseUrl = process.env.REACT_APP_BASE_URL;

const ViewTranscation = () => {
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);

  const formatDate = (date) => {
    return moment(date).format("dddd, MMMM Do, YYYY");
  }
  const classes = useStyles();

  const navigate = useNavigate()
  const { transcationId } = useParams()
  const [transcationDetails, setTranscationDetails] = useState('')
  const [activityList, setActivityList] = useState([])
  const [status, setStatus] = useState('')
  const [total, setTotal] = useState(0)
  const [businessInfo, setBusinessInfo] = useState({})
  const [receiptData, setReceiptData] = useState()
  const [invoicePayments, setInvoicePayments] = useState();
  const [invoiceBalance, setInvoiceBalance] = useState();
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  let localCurrency = businessInfo?.localCurrency

  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
  }
  const dispatch = useDispatch()
  console.log(status, 'statusstatuss');
  // Activity History
  const getTranscationDetails = () => {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/get_funds_transfer/${transcationId}`,
        body: null,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        // console.log(data, 'data getTranscationDetails');

        if (data.status === 200) {
          let transcationDetails = data.response.data
          // console.log(transcationDetails, 'transcationDetails getTranscationDetails');
          let activityList = transcationDetails.actionList
          setActivityList(activityList)
          setTranscationDetails(transcationDetails)
          setStatus(transcationDetails?.transactionStatus)


        }
      })
    } catch (error) {

    }

  }
  useEffect(() => {
    getTranscationDetails()
  }, [])
  // console.log(transcationDetails,activityList, 'transcationDetails transcationDetails');

  const retryTranscation = () => {
    dispatch(clearPayable())
    dispatch(addPayable({ payableStatus: 'Retry', transcationIds: [transcationId] }))
    navigate('/payables/transcations?verifyPayable')
  }
  const cancelTranscation = () => {
    dispatch(clearPayable())
    dispatch(addPayable({ payableStatus: 'Cancel', transcationIds: [transcationId] }))
    navigate('/payables/transcations?verifyPayable')
  }
  const approveTranscation = () => {
    dispatch(clearPayable())
    dispatch(addPayable({ payableStatus: 'Approve', transcationIds: [transcationId] }))
    navigate('/payables/transcations?verifyPayable')
  }
  const declineTranscation = () => {

    dispatch(clearPayable())
    dispatch(addPayable({ payableStatus: 'Disapprove', transcationIds: [transcationId] }))
    navigate('/payables/transcations?verifyPayable')

  }
  const [stateShow, setStateShow] = useState(false);

  const downloadPDF = async () => {

    const inputContainer = document.getElementById('downloadable');
    html2pdf(input, {
      margin: 10,
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }).from(inputContainer).save();

  }


  const viewPDF = () => {
    const input = document.getElementById("downloadable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const inputWidthPx = input.clientWidth;
      const inputHeightPx = input.clientHeight;
      const dpi = window.devicePixelRatio * 96;
      let scaleFactor = 1;
      if (window.innerWidth > 1512) {
        scaleFactor = 1.4;
      }
      const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
      const inputHeightMm = (inputHeightPx / dpi) * 25.4;
      const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
      pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);

      // Create a modal dialog to display the PDF
      const modal = document.createElement("div");
      modal.style.position = "fixed";
      modal.style.top = "0";
      modal.style.left = "0";
      modal.style.width = "100%";
      modal.style.height = "100%";
      modal.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      modal.style.display = "flex";
      modal.style.alignItems = "center";
      modal.style.justifyContent = "center";
      modal.style.zIndex = "1000";

      // Create an iframe to load the PDF content
      const iframe = document.createElement("iframe");
      iframe.style.width = "80%";
      iframe.style.height = "80%";
      iframe.src = pdf.output("bloburl");
      modal.appendChild(iframe);

      // Add the modal to the document
      document.body.appendChild(modal);

      // Close the modal when the user clicks outside of it
      modal.addEventListener("click", () => {
        document.body.removeChild(modal);
      });
    });
  };
  const [fileName, setFileName] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const invoiceDetails = () => {
    console.log(transcationDetails, 'transcationDetails <<<<>>>>')
    let invoiceId = transcationDetails?.invoice[0];
    console.log(invoiceId, 'invoiceIdinvoiceId');
    try {
      const response = HttpComponent({
        method: 'GET',
        url: `/api/v1/invoice/invoiceDetails?invoiceId=${invoiceId}`,
        body: null,
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        console.log(data, 'data invoiceDetails');
        if (data.status === 200) {
          let invoiceDetails = data.response.data;
          let approverName = transcationDetails?.expectedApproverEachLevel[0]?.approves[0]?.approverName;
          let approverPhone = transcationDetails?.expectedApproverEachLevel[0]?.approves[0]?.approverPhone;

          invoiceDetails = { ...invoiceDetails, approverName, approverPhone };
          setReceiptData(invoiceDetails);
          setFileName(data.response.data.fileName);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };


  // const invoiceDetails = () => {
  //   console.log(transcationDetails, 'transcationDetails <<<<>>>>')
  //   let invoiceId = transcationDetails?.invoice[0]
  //   console.log(invoiceId, 'invoiceIdinvoiceId');
  //   try {
  //     const response = HttpComponent({
  //       method: 'GET',
  //       url: `/api/v1/invoice/invoiceDetails?invoiceId=${invoiceId}`,
  //       body: null,
  //       token: localStorage.getItem('X-Authorization')
  //     }).then((data) => {
  //       console.log(data, 'data invoiceDetails');
  //       if (data.status === 200) {
  //         let invoiceDetails = data.response.data
  //         let approverName = transcationDetails?.expectedApproverEachLevel[0]?.approves[0]?.approverName
  //         let approverPhone = transcationDetails?.expectedApproverEachLevel[0]?.approves[0]?.approverPhone
  //         invoiceDetails = { ...invoiceDetails, approverName, approverPhone }
  //         setReceiptData(invoiceDetails)

  //       }
  //     })
  //   } catch (error) {
  //   }
  // }

  useEffect(() => {
    if (transcationDetails) {
      invoiceDetails()
    }
  }, [transcationDetails])
  const renderInput = (props) => (
    <TextField {...props} variant="outlined" fullWidth />
  );

  const [showReconcileState, setShowReconcileState] = useState(false)
  const [formData, setFormData] = useState({
    transactionId: transcationId,
    transactionDate: '',
    statusDescription: '',
    transactionStatus: '',
    ftReference: '',

  })

  const transactionStatusOptions = [
    { label: 'Success', value: 'SUCCESS' },
    { label: 'Failed', value: 'FAILED' },
  ]





  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

  }

  const checkIftransactionStatusChanging = () => {
    if (formData?.transactionStatus === "SUCCESS") {
      setFormData({ ...formData, statusDescription: "Processed Successfully" })
    } else {
      setFormData({ ...formData, statusDescription: "" })
    }

  }

  useEffect(() => {
    checkIftransactionStatusChanging()
  }, [formData?.transactionStatus])

  const handleTransactionDateChange = (newValue) => {
    console.log(newValue, 'newValuenewValuenewValuenewValuenewValue');
    let newDate = new Date(newValue)

    setFormData({ ...formData, transactionDate: newDate });
  };

  const [buttonStateReconcile, setButtonStateReconcile] = useState(false)

  const checkIfButtonReconcileStatus = () => {
    if (formData?.transactionStatus === "SUCCESS") {
      if (formData?.transactionDate && formData?.ftReference) {
        setButtonStateReconcile(true)
      } else {
        setButtonStateReconcile(false)
      }

    }
    if (formData?.transactionStatus === "FAILED") {
      if (formData?.transactionDate && formData?.statusDescription) {
        setButtonStateReconcile(true)
      } else {
        setButtonStateReconcile(false)
      }
    }
  }


  useEffect(() => {
    checkIfButtonReconcileStatus()
  }, [formData?.transactionStatus, formData?.transactionDate, formData?.statusDescription, formData?.ftReference])



  const reconcileFT = async () => {
    console.log(formData, 'formDataformDataformDataform');


    try {
      await HttpComponent({
        method: 'POST',
        url: `/api/zed/v1/funds_transfer/reconcilationFT`,
        body: formData,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'data reconcileFT');
        if (data.status === 200) {
          setShowReconcileState(false)
          setSuccessShow({ ...successShow, state: true, message: "Reconciled Successfully" })
          getTranscationDetails()
        } else {
          setErrorShow({ ...errorShow, state: true, message: data.response.message })
        }
      })
    }
    catch (error) {
      console.log(error, 'error reconcileFT');
      // setErrorShow({ ...errorShow, state: true, message: error.response.data.message })

    }
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const downloadInvoice = async () => {
    try {
      const imageUrl = `${baseUrl}/staticimages/fundsTransferInvoices/${transcationDetails?.attachement}`;
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with the desired file name
      link.download = 'attachment_image.png';

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };
  const [reconcilationButtonShow, setReconcilationButtonShow] = useState(false)
  const checkReconcilationStatus = () => {
    // it should check if like 5mins has passed and othe conditions i.e transcationDetails?.createdAt 
    let transactionDate = new Date(transcationDetails?.createdAt)
    let currentDate = new Date()
    let difference = currentDate - transactionDate
    let minutesDifference = Math.floor(difference / 1000 / 60);
    console.log(minutesDifference, 'minutesDifference');
    if (minutesDifference > 5) {
      if (transcationDetails?.transactionStatusMessage?.includes("The request has been sent to the Bank") || transcationDetails.transactionStatusMessage === "Your transaction request has been submitted to Bank successfully") {
        setReconcilationButtonShow(true)
      } else {
        setReconcilationButtonShow(false)
      }
    } else {
      setReconcilationButtonShow(false)
    }



  }

  useEffect(() => {
    checkReconcilationStatus()
  }, [transcationDetails?.createdAt, transcationDetails?.transactionStatusMessage])


  return (
    <Box >
      {showReconcileState ? <Box sx={{ width: "450px" }}>
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <Grid container >
          <Grid container direction={'row'} style={{ display: "flex" }}>
            <ArrowBackIosIcon style={{ marginTop: "5px", fontSize: "28px" }} onClick={() => navigate(-1)} />
            <Typography key={1} style={{ color: "#032440", fontSize: "22px", fontFamily: "Poppins" }}>  Preview Payable Transactions</Typography>
          </Grid>
          <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
            {reconcilebreadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid sx={{ marginTop: "20px" }}>
          <CustomInputLabel label="Transcation Status" required={true} />
          <CustomSelect value={formData?.transactionStatus} name={"transactionStatus"} placeholder={"Transaction Status"} onChange={handleInputChange} options={transactionStatusOptions} />
        </Grid>
        <Grid sx={{ marginTop: "20px" }}>
          <CustomInputLabel label="Status Description" required={true} />
          <TextField
            name={"statusDescription"}
            value={formData.statusDescription}
            onChange={handleInputChange}
            placeholder={formData?.transactionStatus === "SUCCESS" ? "Processed Successfully" : "status Description"}
            variant="outlined"
            margin="dense"
            sx={{ marginBottom: 2 }}
            multiline
            disabled={formData?.transactionStatus === "SUCCESS" ? true : false}
            minRows={2}
            InputProps={{
              sx: {
                width: "26rem",
                fontSize: "0.875rem",
                "& fieldset": {
                  borderColor: "#bec5d1",
                },
              },
            }}
          />

        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Transaction Date"
            name={"transactionDate"}
            inputFormat="DD/MM/YYYY"
            value={formData.transactionDate}
            required
            minDate={null} // Allow past dates
            onChange={handleTransactionDateChange}
            renderInput={(params) => <TextField style={{ width: "420px" }} {...params} />}
          />
        </LocalizationProvider>

        {formData?.transactionStatus === "SUCCESS" ? <>
          <Grid sx={{ marginTop: "20px" }}>
            <CustomInputLabel label="Bank Reference No" required={true} />
            <CustomTextField value={formData.ftReference} name={"ftReference"} onChange={handleInputChange} />
          </Grid>
        </> : null}

        <Grid sx={{ marginTop: "20px" }}>
          <Box style={{ display: "flex", justifyContent: "end", }}>
            <Button variant="contained" style={{ backgroundColor: "#ffff", color: "#dc3545", border: "2px solid #dc3545", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => setShowReconcileState(false)}>Cancel</Button>
            <Button variant="contained" style={{ backgroundColor: !buttonStateReconcile ? "#707070" : "#032440", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} disabled={!buttonStateReconcile} onClick={() => reconcileFT()}>Reconcile</Button>
          </Box>

        </Grid>
      </Box> :
        <>
          <Grid >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container >
                <Grid container direction={'row'} style={{ display: "flex" }}>
                  <ArrowBackIosIcon style={{ marginTop: "5px", fontSize: "28px" }} onClick={() => navigate(-1)} />
                  <Typography key={1} style={{ color: "#032440", fontSize: "22px", fontFamily: "Poppins" }}>  Preview Payable Transactions</Typography>
                </Grid>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                  {breadcrumbs}
                </Breadcrumbs>
              </Grid>

              <Grid container direction={'row'} style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                <Grid item style={{ width: "600px", }}>
                  {/* {console.log(status, 'status <<<>>>>>>')} */}

                  <Grid container style={{ backgroundColor: status === 'Pending' ? "#FFF4E7" : status === "Disbursed" ? "#E7F6F3" : "#F3F3F2", width: "700px", borderRadius: "5px", padding: "20px" }}>
                    <span style={{ fontSize: "16px", fontWeight: "900", color: status === 'Pending' ? "#F79108" : status === "Disbursed" ? "#17AE7B" : "#dc3545" }}>Status : {transcationDetails.transactionStatusMessage} </span>
                  </Grid>
                  <Grid container direction={'row'} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item >
                      {/* {console.log(transcationDetails, 'transcationDetails <<<<>>>>')} */}
                      <p style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Request ID :</p>
                      <p style={{ fontSize: "14px", fontWeight: "900", color: "#707070", }}>{transcationDetails?.ftpNumber}</p>
                    </Grid>
                    <Grid item >
                      <p style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Request On : </p>
                      <p style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{DateFormatter(transcationDetails?.createdAt)} </p>
                    </Grid>

                  </Grid>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <Grid item >
                    {/* {console.log(transcationDetails, 'transcationDetails <<<<>>>>')} */}

                    <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>{transcationDetails?.transcationName === "Supplier Payment" ? "Supplier Details" : "Payee Details"}</p>
                  </Grid>
                  <Grid container >
                    <Table style={{ width: "600px" }}>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Name</TableCell>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.supplierName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Amount : </TableCell>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}> {transcationDetails?.currency} {transcationDetails?.totalAmount}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Bank Name : </TableCell>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.supplierBankName} {transcationDetails?.creditAccountNumber}</TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item >
                    {/* {console.log(transcationDetails, 'transcationDetails <<<<>>>>')} */}

                    <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>Transcation Details</p>
                  </Grid>
                  <Grid container >
                    <Table style={{ width: "600px" }}>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Transaction Type:</TableCell>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.transactionType}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Debit Account:</TableCell>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}> {transcationDetails?.debitBankName}  {transcationDetails?.debitAccountNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Description: </TableCell>
                          <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.transactionDescription}</TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", marginRight: "10px" }}>Attachment:</p>

                    {fileName && (
                      <>
                        <p style={{ marginRight: "20px" }}>{fileName.split('/').pop()}</p>
                        <Button onClick={handleOpenModal} style={{ padding: "6px 12px", backgroundColor: "rgb(251, 250, 251)", color: "black", border: "none", cursor: "pointer", borderRadius: "4px", marginLeft: "350px", marginTop: '5px' }}>
                          Preview
                        </Button>
                      </>
                    )}
                  </Grid>

                  <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      Image Preview
                      <IconButton onClick={handleCloseModal}>
                        <CloseIcon /> 
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      {fileName ? (
                        <img src={fileName} alt="Preview" style={{ width: '100%', height: 'auto' }} />
                      ) : (
                        <p>No image available</p>
                      )}
                    </DialogContent>
                  </Dialog>
                  {/* <Grid item >
                    {console.log(transcationDetails, 'transcationDetails <<<<>>>>')}

                    <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>Attachment</p>

                  </Grid> */}
                  <Grid item >
                    {/* {console.log(transcationDetails, 'transcationDetails <<<<>>>>')} */}
                    {transcationDetails?.actionType === "Retry" ?

                      <Box style={{ display: "flex", justifyContent: "end" }}>
                        <Button variant="contained" style={{ backgroundColor: "#17AE7B", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => retryTranscation()}>Retry</Button>
                      </Box>
                      : transcationDetails?.canCancel === true ?
                        <Box style={{ display: "flex", justifyContent: "end" }}>
                          <Button variant="contained" style={{ backgroundColor: "#17AE7B", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => cancelTranscation()}>Cancel</Button>
                        </Box>
                        : transcationDetails?.actionType === "ApproveDecline" ?

                          <Box style={{ display: "flex", justifyContent: "end", marginTop: '10px' }}>
                            <Button variant="contained" style={{ backgroundColor: "#17AE7B", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => approveTranscation()}>Approve</Button>
                            <Button variant="contained" style={{ backgroundColor: "#dc3545", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => declineTranscation()}>Decline</Button>

                          </Box>
                        : transcationDetails?.actionType === "SentToBankTimeout" ?
                          <Box style={{ display: "flex", justifyContent: "end" }}>
                            <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => setShowReconcileState(true)}>Reconcile</Button>

                          </Box>
                          // check if it passed like 5mins
                          // : reconcilationButtonShow ?

                          //   <>
                          //     <Box style={{ display: "flex", justifyContent: "end" }}>
                          //       <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", width: "125px", height: "45px", marginRight: "10px", Padding: "12.5px 42.7px 12.5px 44.3px", borderRadius: "4px" }} onClick={() => setShowReconcileState(true)}>Reconcile</Button>

                          //     </Box>

                          //   </>
                            : null}

                  </Grid>

                </Grid>
                <Grid item style={{ minHeight: "70vh", width: "40%" }}>

                  <Grid item style={{ display: "flex", justifyContent: "space-between", alignContent: "space-between", paddingRight: "40px" }}>
                    <Grid item></Grid>
                    <Grid style={{ display: "flex", justifyContent: "flex-end", alignContent: "flex-end" }}>
                      <FileDownloadOutlinedIcon style={{ marginRight: "10px", marginTop: "3px", color: "#032541", fontSize: "40px", }} onClick={downloadPDF} />
                    </Grid>
                  </Grid>
                  <Grid item style={{ backgroundColor: "#FBFAFB", padding: "0px 40px" }}>
                    <p>Activity  History</p>
                    {console.log(activityList, 'activityListactivityListactivityList')}
                    {activityList?.map((item, index) =>
                      <List key={index}>
                        <div style={{ display: "flex", marginRight: "10px" }}>
                          <FiberManualRecordIcon style={{ marginRight: "10px", marginTop: "3px", color: "#e0e0e0", fontSize: "14px" }} />

                          <div>
                            <div>{item?.message}</div>
                            <div style={{ color: "#707070" }}>{DateFormatter(item?.createdAt)}</div>
                          </div>

                        </div>

                      </List>
                    )}</Grid>


                </Grid>

              </Grid>


            </Grid>

          </Grid>
          <>
            {status === "Disbursed" ? <>

              <div style={{ width: "50%", marginLeft: "5%" }}>
                <div>
                  <div>


                    <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
                      <div className={classes.p3} style={{ backgroundColor: `rgba(3,37,65,0.05)`, height: "150px", display: "flex", justifyContent: "space-between", }}>

                        <div className={classes.p1} style={{ display: "flex", flexDirection: "column", }}>
                          <span style={{ color: "black", fontSize: "30px", fontWeight: "700", }}>PAYMENT RECEIPT</span>
                          <span style={{ color: "black", fontSize: "14px", fontWeight: "700", }}>Date:</span>
                          <span style={{ fontSize: "14px" }}>{formatDate(receiptData?.createdAt)}</span>
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                            <span style={{ color: "black", fontSize: "14px", fontWeight: "700", textAlign: "left" }}>Receipt No: <span style={{ fontSize: "14px" }}>{receiptData?.ftReference}</span></span>

                          </div>
                        </div>


                        <div style={{ display: "flex", flexDirection: "column", }}>
                          <img src={`${baseUrl}/staticimages/logos/logozed.png`} style={{ width: "150px", height: "150px", objectFit: "contain", }} />

                        </div>

                      </div>
                      <div className={classes.p3} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                        <div style={{ display: "flex", flexDirection: "column", }}>
                          <span style={{ fontSize: "18px", }}>From:</span>
                          <span className={classes.address_text_common} style={{ fontWeight: "700" }}>{receiptData?.supplierName || transcationDetails?.supplierName}</span>
                          <span className={classes.address_text_common}>{receiptData?.supplierEmail}</span>
                          <span className={classes.address_text_common}>{receiptData?.supplierPhone}</span>
                          <span className={classes.address_text_common}>{receiptData?.supplierEmail}</span>
                        </div>
                        <div className={classes.mt4} style={{ display: "flex", flexDirection: "column", textAlign: "right", }}>
                          <span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
                          <span className={classes.address_text_common}>{localStorage.getItem('businessName')}</span>
                          {/* transcationDetails?.expectedApproverEachLevel[0]?.approves[0]?.approverName */}
                          <span className={classes.address_text_common}>{receiptData?.approverName}</span>

                          <span className={classes.address_text_common}>{localStorage.getItem('email')}</span>
                          {/* transcationDetails?.expectedApproverEachLevel[0]?.approves[0]?.approverPhone || "----" */}
                          <span className={classes.address_text_common}>{receiptData?.approverPhone}</span>
                        </div>
                      </div>
                      <br />
                      <div style={{ border: "dotted 1px" }}></div>
                      <br />
                      <div className={classes.p3} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                        <div className={classes.p3} style={{ display: "flex", justifyContent: "start", flexDirection: "column" }}>
                          <span style={{ fontSize: "14px", fontWeight: "700", }}>Description</span>
                          <span style={{ fontSize: "14px", fontWeight: "300", }}>{transcationDetails?.transactionDescription}</span>

                          <span style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>Paid FROM</span>
                          <span style={{ fontSize: "14px", fontWeight: "300", }}>{transcationDetails?.debitBankName}  {transcationDetails?.debitAccountNumber}</span>

                          <span style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>Payment Mode</span>
                          <span style={{ fontSize: "14px", fontWeight: "300", }}>{transcationDetails?.transactionType}</span>
                          <span style={{ fontSize: "14px", fontWeight: "300", }}>{receiptData?.ftReference}</span>




                        </div>
                        <div className={classes.p3} style={{ display: "flex", justifyContent: "end", }}>
                          <div className={classes.p1} style={{ width: "225px", height: "170px", display: "flex", flexDirection: "column", justifyContent: "center", border: "dotted 1px #17ae7b" }} >
                            <span style={{ fontSize: "14px", color: `#17ae7b`, fontWeight: "bold", textAlign: "center" }}>Total Amount</span>
                            <span style={{ fontSize: "36px", color: `#17ae7b`, fontWeight: "bold", textAlign: "center" }} >
                              {numberFormat(transcationDetails?.totalAmount)}
                            </span>
                          </div>
                        </div>
                      </div>


                    </div>
                    <br />
                    <div style={{ border: "dotted 1px" }}></div>
                    <br />

                    <div
                      className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                      <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}> This is a system-generated receipt is created without any alteration whatsoever. <br /> Thank you for your business.
                      </div>
                    </div>
                    <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }}  >
                      <span className={classes.footer_text}> Powered by <span style={{ fontWeight: "700" }}>ZED Payments Limited</span>
                      </span>
                      <span className={classes.footer_text}>.</span>
                      <span className={classes.footer_text}>info@zed.business</span>
                      <span className={classes.footer_text}>.</span>
                      <span className={classes.footer_text}>v1.0.2</span>
                    </div>






                  </div>
                </div>
              </div>

            </> : null}

          </>
        </>

      }

    </Box>
  )
}

export default ViewTranscation